import { AccountItem } from '@/models/account_item'
import { AccountApi } from '@/apis/account_api'
import eventBus, { EVENT } from '@/EventBus'
import { Auth } from '@/apis/mylixil_auth'
import { useRouter } from 'vue-router'
import { defineStore } from 'pinia'

//in script setup or inside the setup hook
const router = useRouter()
const accountApi: AccountApi = new AccountApi()
let accountItem: AccountItem
export interface IName {
  familyName: string
  familyNameKana: string
  givenName: string
  givenNameKana: string
}
export const userStoreAccount = defineStore('account', {
  state: () => ({
    signInStatus: false,
    accountId: '',
    accountName: '',
    userLevel: '',
    itemGroups: [] as string[],
    userName: '',
    telephone: '',
    language: '',
    notifyGroupIDs: [],
    mail: '',
    name: {} as IName,
  }),

  getters: {},
  actions: {
    async getAccount(reset = false): Promise<boolean> {
      try {
        accountItem = await accountApi.getAccount(reset)
        eventBus.$emit(EVENT.DESTROY)
        eventBus.$emit(EVENT.REGISTER)
        this.updateSate(accountItem)
        return true
      } catch (error: any) {
        const err = error.response
        if (!err) {
          const info = await Auth.getUserInfo()
          const { family_name, family_name_kana, given_name, given_name_kana } = info
          this.name = {
            familyName: family_name,
            familyNameKana: family_name_kana,
            givenName: given_name,
            givenNameKana: given_name_kana,
          } as IName
          router.push('/unauthorized')
        }
        return false
      }
    },
    signOut(): void {
      eventBus.$emit(EVENT.DESTROY)
      this.accountId = ''
      this.userLevel = ''
      this.itemGroups = []
      this.userName = ''
      this.telephone = ''
      this.language = ''
      this.mail = ''
      this.signInStatus = false
      this.name = {
        familyName: '',
        familyNameKana: '',
        givenName: '',
        givenNameKana: '',
      }
    },
    updateSate(payload: any): void {
      const { familyName, familyNameKana, givenName, givenNameKana } = payload
      this.name = { familyName, familyNameKana, givenName, givenNameKana } as IName
      this.accountId = payload.accountId
      this.userLevel = payload.userLevel
      this.itemGroups = payload.itemGroups
      this.userName = payload.userName
      this.telephone = payload.telephone
      this.language = payload.language
      this.signInStatus = true
      this.mail = payload.mail
    },
  },
})
