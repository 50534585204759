import { defineStore } from 'pinia'

export const appStore = defineStore('app', {
  state: () => ({
    sorry: false,
  }),

  getters: {},
  actions: {
    updateSate(payload: any): void {
      this.sorry = payload
    },
  },
})
