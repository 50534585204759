import { Auth } from '@/apis/mylixil_auth'
import { RoleBaseChecker, IUserLevel } from '@/utils'
import { createRouter, createWebHistory } from 'vue-router'
import { userStoreAccount } from './store/modules/account'
import { appStore } from './store/modules/app'

import Cookies from 'js-cookie'

const roleChecker: RoleBaseChecker = new RoleBaseChecker()
function lazyRoute(path: string, name: string, view: string, requiresAuth = true) {
  return {
    path,
    name,
    component: () => import(`@/views/${view}.vue`),
    meta: { requiresAuth },
  }
}
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/signin',
    },
    lazyRoute('/:catchAll(.*)', 'pageNotFound', 'PageNotFound', false),
    lazyRoute('/signin', 'signin', 'SignIn', false),
    lazyRoute('/unauthorized', 'unauthorized', 'Unauthorized', false),
    lazyRoute('/places', 'places', 'places/PlaceListNew'),
    lazyRoute('/connect', 'gateway', 'gateway/Gateway'),
    lazyRoute('/places/create/:placeId?', 'create_place', 'places/PlaceForm'),
    lazyRoute('/places/update/:placeId', 'update_place', 'places/PlaceForm'),
    lazyRoute('/devices', 'devices', 'devices/DeviceList'),
    lazyRoute('/devices/update/:deviceId', 'update_device', 'devices/DeviceForm'),
    lazyRoute('/menu', 'menu', 'Menu'),
    lazyRoute('/item_groups', 'item_groups', 'ItemGroupList'),
    lazyRoute('/users', 'users', 'UserList'),
    lazyRoute('/sorry', 'maintenance', 'sorry/Maintenance', false),
    lazyRoute('/sorry', 'sorry', 'sorry/Sorry', false),
    lazyRoute('/callback', 'callback', 'Callback', false),
    lazyRoute('/404', '404', '404NotFound', false),
  ],
})

router.beforeEach(async (to: any, from, next) => {
  const store = userStoreAccount()
  const { sorry, updateSate } = appStore()
  if (to.fullPath === '/sorry' && Auth.isAuthenticated()) {
    if (!sorry) {
      updateSate(false)
      const previousRoute = Cookies.get('previousRoute')!

      next({ name: previousRoute })
    }
  }

  if (!to.meta.requiresAuth) return next()
  if (!Auth.isAuthenticated()) {
    return next({ path: '/' })
  }
  let userLevel: keyof IUserLevel = store.userLevel as keyof IUserLevel

  if (to.name !== 'signin') {
    if (to.fullPath !== '/sorry') {
      const lastPath = Cookies.get('lastPath')!

      if (to.name !== lastPath) {
        Cookies.set('previousRoute', lastPath)
      }
      Cookies.set('lastPath', to.name)
    }
    if (!store.userLevel) {
      await store.getAccount()
      userLevel = store.userLevel as keyof IUserLevel
      if (!roleChecker.canAccess('', userLevel)) next({ name: 'unauthorized' })
    }
    if (to.path.indexOf('places') >= 0) {
      roleChecker.canAccess('places', userLevel) ? next() : next({ name: 'unauthorized' })
    } else if (to.path.indexOf('devices') >= 0) {
      roleChecker.canAccess('devices', userLevel) ? next() : next({ name: 'unauthorized' })
    } else if (to.path.indexOf('users') >= 0) {
      roleChecker.canAccess('users', userLevel) ? next() : next({ name: 'unauthorized' })
    } else {
      next()
    }
  } else {
    if (await Auth.isAuthenticated()) {
      // redirect if signed in
      next({ path: 'menu' })
    } else {
      next()
    }
  }
})

export default router
