import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { apiSetting } from '@/aws-exports'
import { GetCookies, Auth } from '@/apis/mylixil_auth'
import eventBus, { EVENT } from '@/EventBus'
import router from '@/router'
import { appStore } from '../store/modules/app'

export default class ApiRequestBase {
  private client: AxiosInstance = axios.create({
    baseURL: apiSetting.internal_base,
  })
  constructor() {
    this.client.interceptors.response.use(
      (res) => res,
      async (err) => {
        const { updateSate } = appStore()
        const originalRequest = err.config

        if (err.message === 'Network Error') {
          router.push({ name: 'sorry' })
        }
        // console.log(JSON.stringify(err));
        const error = err.response
        const reg = /^(5)\d\d$/

        if (error && error.status === 401) {
          try {
            return Auth.refesh()
          } catch (err) {
            eventBus.$emit(EVENT.SIGNOUT)
          }
        } else if (error.status === 307) {
          Auth.removeCookie()
          router.push({ name: 'maintenance' })
        } else if (
          error.status === 403 &&
          error.data.message ===
            'User is not authorized to access this resource with an explicit deny'
        ) {
          await Auth.refesh()
          const token = GetCookies.getInstance().getJsonToken().id_token
          originalRequest.headers['Authorization'] = token

          return this.client(originalRequest)
        } else if (reg.test(error.status)) {
          updateSate(true)
          router.push({ name: 'sorry' })
        }
        return Promise.reject(err)
      },
    )
  }
  protected async get<T>(path: string, option: object = {}): Promise<AxiosResponse<T>> {
    const baseOption = await this.createBaseOption()
    const customOption = Object.assign({}, baseOption, option)
    const ret = await this.client.get<T>(path, customOption)
    return ret
  }

  protected async delete<T>(path: string, option: object = {}): Promise<AxiosResponse<T>> {
    const baseOption = await this.createBaseOption()
    const customOption = Object.assign({}, baseOption, option)
    const ret = await this.client.delete<T>(path, customOption)
    return ret
  }

  protected async post<T, S>(
    path: string,
    data: S,
    option: object = {},
  ): Promise<AxiosResponse<T>> {
    const baseOption = await this.createBaseOption()
    const customOption = Object.assign({}, baseOption, option)
    const ret = await this.client.post<T>(path, data, customOption)
    return ret
  }

  protected async put<T, S>(path: string, data: S, option: object = {}): Promise<AxiosResponse<T>> {
    const baseOption = await this.createBaseOption()
    const customOption = Object.assign({}, baseOption, option)
    const ret = await this.client.put<T>(path, data, customOption)
    return ret
  }

  protected async patch<T, S>(
    path: string,
    data: S,
    option: object = {},
  ): Promise<AxiosResponse<T>> {
    const baseOption = await this.createBaseOption()
    const customOption = Object.assign({}, baseOption, option)
    const ret = this.client.patch<T>(path, data, customOption)
    return ret
  }

  private async createBaseOption(): Promise<object> {
    const token = GetCookies.getInstance().getJsonToken().id_token
    return {
      headers: {
        Authorization: token,
      },
    }
  }
}
